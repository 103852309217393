<template>
    <b-container>
      <b-row>
        <b-col>
          <b-card title="Registration / Application Fees and Reimbursement Policies">
            <p>
              Any student intending to avail AOPL university application services is required to register with AOPL against a registration fee, payable in Indian currency.
            </p>
            <p>
              Registration fee is not refundable (except as mentioned in 3) or transferable and does not constitute a deposit.
            </p>
            <p>
              AOPL through its promotion may offer a discount or reimburse the registration fee (in part or full) to selective students at its discretion. Such discount or reimbursement would be in accordance with the terms & conditions mentioned in the promotion offered to the student.
            </p>
            <p>
              Any reimbursement made to the student/parent will not bear interest.
            </p>
            <p>
              Reimbursement can be claimed only after full and final payment of all other financial commitments of the student/parent towards AOPL/Overseas Institutions.
            </p>
            <p>
              Any reimbursement claim made after 180 days from the date of joining the Overseas Institution will not be entertained.
            </p>
            <p>
              Further to registration, students may be required to pay the application fee directly to the Overseas Institution as per its described rules and regulations with discretionary assistance from AOPL. The refund of such application fee will be at the discretion of the Overseas Institution.
            </p>
            <p>
              Registration or application fees mentioned in any communication from AOPL are indicative and may vary or change during the actual registration or application process. Students are advised to check with AOPL for the final registration or application amount to be paid.
            </p>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card title="Tuition Fee and Refund Policies">
            <p>
              Tuition Fee paid to Overseas Institution, are generally in foreign currency. Student/Parent need to consider and pay as per the prevailing foreign exchange rate. Hence, the fee mentioned during admission may vary with the fee actually paid.
            </p>
            <p>
              Once a student joins an overseas institution, it is the responsibility of the student/parent to know the next payment due date and pay the tuition fee on or before the deadline.
            </p>
            <p>
              Overseas Institution(s) at its own discretion may revise tuition fee, with or without prior notice. This may result in a difference between the tuition fee mentioned in the offer/admission letter issued at the time of admission and the actual tuition fee payment. Any such revision of fee is bound upon the student/parent.
            </p>
            <p>
              It is the responsibility of the student/parent to get familiar with the tuition fee refund policy of the overseas institution they select.
            </p>
            <p>
              Some overseas institutions demand tuition fees in advance, i.e., before/after the offer letter or before/after visa approval.
            </p>
            <p>
              Students claiming a refund of advance tuition fees, due to whatsoever reason, have to fulfill the formalities to receive a refund of the tuition fee paid to the overseas institution.
            </p>
            <p>
              Further, refunds may be subject to certain deductions as per the policy of the overseas institution and will be in accordance with the prevailing foreign exchange rate.
            </p>
            <p>
              Student/parent must provide accurate bank details and information to the overseas institution for the refund to take place.
            </p>
            <p>
              AOPL, at its discretion, may assist the student/parent to get a refund of tuition fees paid to the overseas institution. However, AOPL is not responsible or liable in any way for refunds of overseas institution tuition fees. Any loss, whether financial or otherwise due to a delay in such refund, is not on AOPL.
            </p>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  
  <script>
  export default {
    name: 'ReimbursementAndRefund',
  }
  </script>
  